// JS ----------
import $ from 'jquery';
global.$ = jQuery;
import 'jquery-ui/ui/widgets/dialog';

import "src/js/jquery.cookie";
import "src/js/nav";
import "src/js/job_list.js";
import "src/js/jquery.autoKana";

import Rails from 'rails-ujs'
Rails.start()

// CSS ----------
const images = require.context('../../images/', true)
import 'src/scss/common/application';
