/*------------------------------------------------
* スクロールイベント管理
------------------------------------------------*/
//scroll event
var scroll_functions = function() {
  //スクロールに応じた処理はこちらに記述する
  scroll_body();
};

//scroll event 負荷低減
var w_scroll = false;
var event_interval_time = 300;
jQuery(window).on("scroll load", function() {
  if (!w_scroll) {
    w_scroll = true;
    setTimeout(function() {
      w_scroll = false;
      scroll_functions();
    }, event_interval_time);
  }
});
//-- スクロール中はbodyに.scroll追加
var scroll_body = function() {
  var scroll_class_name = "scroll";
  if (jQuery(this).scrollTop() > 0) {
    jQuery("body").addClass(scroll_class_name);
  } else {
    jQuery("body").removeClass(scroll_class_name);
  }
  if (jQuery(this).scrollTop() > 700) {
    jQuery("body").addClass(scroll_class_name+"_700");
  } else {
    jQuery("body").removeClass(scroll_class_name+"_700");
  }
};

$(function(){
	$('header').prepend('<div class="overlay"></div>');

	$('.navBtn').click(function() {
		$('header').toggleClass('navOpen');
		$('.wrapper').toggleClass('fixed');
		$('.overlay').toggle();
	});

	$('.overlay').click(function() {
		$(this).fadeOut(300);
		$('header').removeClass('navOpen');
		$('.wrapper').removeClass('fixed');
	});

	$(window).on('load resize', function() {
		var breakpoint = 768;
		if (window.innerWidth > breakpoint) {
			$('.wrapper').removeClass('fixed');
			$('.overlay').hide();
			$('header nav').css('marginTop', 0 + 'px');
		}
	});
});


$(function() {
	var scrollNav = $('.pagetop,.registration,.registration_sp');
	scrollNav.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 500) {
			scrollNav.fadeIn();
		} else {
			scrollNav.fadeOut();
		}
	});

	$('a[href^="#"]').click(function() {
	  var speed = 400;
	  var href= $(this).attr("href");
	  var target = $(href == "#" || href == "" ? 'html' : href);
	  var position = target.offset().top;
	  $('body,html').animate({scrollTop:position}, speed, 'swing');
	  return false;
   });
});
