$(function(){
	$('.job_search_status_reload-btn,.job_search_form_close').on('click',function(){
		$('body').toggleClass('is-sp-search-modal-open');
		$('.job_search_form.sp_modal .job_search_form_submit').toggleClass('hidden');
	});
	$('.job_search_form_additional_conditions_toggle').each(function(){
		var toggle_wrapper = $('.job_search_form_additional_conditions');
		$(this).find('.btn_open').on('click',function(){
			toggle_wrapper.toggleClass('is-toggle-open');
		});
		$(this).find('.btn_close').on('click',function(){
			toggle_wrapper.toggleClass('is-toggle-open');
		});
	});
});
